import { User } from "../lib/classes/user-class";
import { db } from "../lib/connect-firebase"; // Make sure the path is correct
import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";

export class UserDataService {
  private _userData = collection(db, "users");

  async getUser({ phoneNumber }: { phoneNumber: string }) {
    try {
      const q = query(this._userData, where("phoneNumber", "==", phoneNumber));

      // Get documents matching the query
      const querySnapshot = await getDocs(q);

      // Check if any documents were found
      if (querySnapshot.empty) {
        return null;
      }

      // Assuming you're fetching the first user with the phone number
      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data() as User; // You can typecast to your `User` class
      return userData;
    } catch (error) {
      console.log('No document found in the "users" collection.');
      return null;
    }
  }

  async addUser(userData: { phoneNumber: string; countryCode: string }) {
    try {
      // Add the user data to the "users" collection
      const user = { ...userData, joinedDate: new Date(), id: uuid() };
      const docRef = await addDoc(this._userData, { ...user });

      console.log("User added with ID:", docRef.id);
      return user;
    } catch (error) {
      console.log("Could not create the user in addUser method.");
      return null;
    }
  }

  async deleteUser({
    phoneNumber,
  }: {
    phoneNumber: string;
  }): Promise<null | "success"> {
    try {
      const q = query(this._userData, where("phoneNumber", "==", phoneNumber));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) return null;

      const userDoc = querySnapshot.docs[0];
      await deleteDoc(userDoc.ref);

      return "success";
    } catch (error) {
      console.log("Could not delete the user in deleteUser method.", error);
      return null;
    }
  }

  async addDroppedUser(userData: {
    phoneNumber: string;
    countryCode: string;
    propertyName: string;
    checkInDate: Date;
    checkOutDate: Date;
  }) {
    try {
      // Add the user data to the "users" collection
      const user = { ...userData, joinedDate: new Date() };
      const droppedUserDb = collection(db, "droppedUsers");
      const docRef = await addDoc(droppedUserDb, { ...user });

      console.log("Dropped User added with ID:", docRef.id);
      return user;
    } catch (error) {
      console.log("Could not create the user in addUser method.");
      return null;
    }
  }

  async editUsersEmailBasedOnNumber({
    phoneNumber,
    email,
  }: {
    phoneNumber: string;
    email: string;
  }) {
    try {
      // Create a query to find the user with the given phoneNumber
      const q = query(this._userData, where("phoneNumber", "==", phoneNumber));

      // Execute the query
      const querySnapshot = await getDocs(q);

      // Check if any documents were found
      if (querySnapshot.empty) {
        console.log("No user found with the provided phone number.");
        return null;
      }

      // Assuming you're updating the first user found with the phone number
      const userDoc = querySnapshot.docs[0];
      const userRef = userDoc.ref;

      // Update the email field
      await updateDoc(userRef, { email });

      console.log("User email updated successfully.");
      return true;
    } catch (error) {
      console.error("Error updating user email:", error);
      return null;
    }
  }

  async editUsersNameBasedOnNumber({
    phoneNumber,
    name,
  }: {
    phoneNumber: string;
    name: string;
  }) {
    try {
      // Create a query to find the user with the given phoneNumber
      const q = query(this._userData, where("phoneNumber", "==", phoneNumber));

      // Execute the query
      const querySnapshot = await getDocs(q);

      // Check if any documents were found
      if (querySnapshot.empty) {
        console.log("No user found with the provided phone number.");
        return null;
      }

      // Assuming you're updating the first user found with the phone number
      const userDoc = querySnapshot.docs[0];
      const userRef = userDoc.ref;

      // Update the name field
      await updateDoc(userRef, { name });

      console.log("User name updated successfully.");
      return true;
    } catch (error) {
      console.error("Error updating user name:", error);
      return null;
    }
  }

  async editUsersProfileImgBasedOnNumber({
    phoneNumber,
    profileUrl,
  }: {
    phoneNumber: string;
    profileUrl: string;
  }) {
    try {
      // Create a query to find the user with the given phoneNumber
      const q = query(this._userData, where("phoneNumber", "==", phoneNumber));

      // Execute the query
      const querySnapshot = await getDocs(q);

      // Check if any documents were found
      if (querySnapshot.empty) {
        console.log("No user found with the provided phone number.");
        return null;
      }

      // Assuming you're updating the first user found with the phone number
      const userDoc = querySnapshot.docs[0];
      const userRef = userDoc.ref;

      // Update the name field
      await updateDoc(userRef, { profileUrl });

      console.log("User profileUrl updated successfully.");
      return true;
    } catch (error) {
      console.error("Error updating user profileUrl:", error);
      return null;
    }
  }
}
