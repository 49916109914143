import { FaSpinner } from "react-icons/fa";
import { IoIosDoneAll } from "react-icons/io";
import { LabelText } from "./common/texts/label-text";
import { Lexend_Deca } from "next/font/google";
import { EditSuccessIcon } from "../../assets/edit-success-icon";

export const lexendDeca = Lexend_Deca({
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  subsets: ["latin"],
});

interface Props {
  name: string;
  type: string;
  value: string;
  error: string;
  setError: (error: string) => void;
  setValue: (error: string) => void;
  label: string;
  isEdit?: boolean;
  setIsEdit?: () => void;
  isEditPending?: boolean;
  placeholder?: string;
}

export const InputBox = ({
  name,
  type,
  value,
  error,
  setError,
  setValue,
  label,
  isEdit,
  isEditPending,
  setIsEdit,
  placeholder,
}: Props) => {
  return (
    <div
      style={{
        borderColor: error ? "#C81717" : "#d5cabd",
        borderWidth: error ? "2px" : "0.5px",
      }}
      className="relative rounded-[2.5px] w-full p-2.5 mobile:p-2 flex flex-col gap-y-2"
    >
      <LabelText className="text-primaryB7 !text-[12px] !font-normal !leading-[6px]">
        {label}
      </LabelText>
      <input
        className={`${lexendDeca.className} text-[16px] font-normal outline-none text-primaryB6 leading-[10px] placeholder:text-neutralN6`}
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={({ target }) => {
          setError("");
          setValue(target.value);
        }}
        placeholder={placeholder ? placeholder : ""}
        // required
      />

      {isEdit && (
        <div className="absolute bottom-1 mobile:bottom-2 right-2 mobile:right-3">
          {isEditPending ? (
            <FaSpinner
              size={25}
              color="#5F5853"
              className="cursor-default infinite-rotate"
            />
          ) : (
            <button onClick={setIsEdit}>
              <EditSuccessIcon />
            </button>
          )}
        </div>
      )}
    </div>
  );
};
