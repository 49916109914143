export const EditSuccessIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.0013 0.666992C4.4063 0.666992 0.667969 4.40533 0.667969 9.00033C0.667969 13.5953 4.4063 17.3337 9.0013 17.3337C13.5963 17.3337 17.3346 13.5953 17.3346 9.00033C17.3346 4.40533 13.5963 0.666992 9.0013 0.666992ZM13.6005 7.16033L8.60964 12.1503C8.48797 12.2728 8.32797 12.3337 8.16797 12.3337C8.00797 12.3337 7.84797 12.2728 7.7263 12.1503L5.2263 9.65033C4.98214 9.40616 4.98214 9.01033 5.2263 8.76616C5.47047 8.52199 5.8663 8.52199 6.11047 8.76616L8.1688 10.8245L12.7171 6.27616C12.9613 6.03199 13.3571 6.03199 13.6013 6.27616C13.8455 6.52033 13.8446 6.91616 13.6005 7.16033Z"
        fill="#56B53B"
      />
    </svg>
  );
};
